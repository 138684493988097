import ApiService from './ApiService'
export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: `Account/Member/Auth`,
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: 'Account/Member/forgetPassword/',
        method: 'post',
        data
    })
}


export async function GetCountDashboard(data) {
    return ApiService.fetchData({
        url: `/Account/GetCountDashboard/`+data,
        method: 'get',
        data
    })
}

export async function GetMemberPortalVendorTypes (data) {
    return ApiService.fetchData({
        url: `/Account/GetMemberPortalVendorTypes`,
        method: 'get',
        data
    })
}
export async function GetMemberPortalCompanyType (data) {
    return ApiService.fetchData({
        url: `/Account/GetMemberPortalCompanyType`,
        method: 'get',
        data
    })
}



export async function GetMemberPortalvendor (data) {
    return ApiService.fetchData({
        url: `/Account/GetMemberPortalvendor`,
        method: 'post',
        data
    })
}

export async function GetVendorContracts (data) {
    return ApiService.fetchData({
        url: `/Account/GetVendorContracts`,
        method: 'post',
        data
    })
}



export async function GetMembers(data) {
    return ApiService.fetchData({
        url: `/Account/Member/MemberSearch`,
        method: 'post',
        data
    })
}

export async function GetRebateData(data) {
    return ApiService.fetchData({
        url: `/Account/Member/RebateData`,
        method: 'post',
        data
    })
}
export async function GetRebateDataView(data) {
    return ApiService.fetchData({
        url: `/Account/Member/RebateData/View`,
        method: 'post',
        data
    })
}

export async function GetRebateDataExport(data) {
    return ApiService.fetchData({
        url: `/Account/Member/ExportRebateData`,
        method: 'post',
        data
    })
}

export async function GetRebateDataExportGroupBy(data) {
    return ApiService.fetchData({
        url: `/Account/Member/ExportRebateDataGroupBy`,
        method: 'post',
        data
    })
}

export async function GetWebinars(data) {
    return ApiService.fetchData({
        url: `/Account/Webinars/List`,
        method: 'post',
        data
    })
}

export async function GetMember(data) {
    return ApiService.fetchData({
        url: `/Account/Member/Get/`+data,
        method: 'get',
        data
    })
}

export async function UpdateMember(data) {
    return ApiService.fetchData({
        url: `/Account/Member/Update`,
        method: 'post',
        data
    })
}










export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: 'http://localhost:8080/api/register/',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: 'http://localhost:8080/api/logout/',
        method: 'post',
        data
    })
}



export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: 'http://localhost:8080/api/reset-password/',
        method: 'post',
        data
    })
}
